import { first } from "rxjs/operators";
import { JobService } from "./../../services/job.service";
import { Component, OnInit } from "@angular/core";
import _ from "lodash";
import { NzMessageService } from "ng-zorro-antd/message";
import { ExcelService } from "src/app/services";
import { Objects } from "src/app/core/objects";
import { User } from "src/app/models";

@Component({
  selector: "dialog-bom",
  templateUrl: "./dialog-bom.component.html",
  styleUrls: ["./dialog-bom.component.scss"],
})
export class DialogBOMComponent implements OnInit {
  constructor(
    public message: NzMessageService,
    private jobService: JobService,
    private excelService: ExcelService
  ) {
    this.objects = new Objects();
  }
  isVisibleMoreInfo: boolean = false;
  isVisible: boolean = false;
  dataBOM: any;
  jobNo: string = "";
  objects: Objects;
  isAdmin: boolean = false;
  currentUser: User;

  ngOnInit(): void {}

  public showTest(_visible: boolean, user: User, lstBOM, lstAddBOM, quoteInfo) {
    this.isVisible = _visible;
    this.jobNo = "Test";
    this.isAdmin = false;
    this.currentUser = user;

    if (!this.isVisible) {
      return;
    }
    this.dataBOM = {
      BOMInfo: [...lstBOM],
      quoteInfo: { ...quoteInfo },
    };
    if (lstAddBOM) {
      const _listAddBOM = lstAddBOM.map((m) => ({
        ...m,
        isAdd: true,
      }));
      this.dataBOM.BOMInfo.push(..._listAddBOM);
    }
    this.isVisibleMoreInfo = false;
    this.isVisible = true;
  }

  public show(
    _visible: boolean,
    id: string,
    jobNo: string,
    isAdmin: boolean,
    user: User
  ) {
    this.isVisible = _visible;
    this.jobNo = jobNo;
    this.isAdmin = isAdmin;
    this.currentUser = user;

    if (!this.isVisible) {
      return;
    }

    // Check changed before
    this.jobService
      .getBOMById(id)
      .pipe(first())
      .subscribe(
        (data) => {
          this.dataBOM = {
            BOMInfo: [...data.data.list],
            quoteInfo: { ...data.data.quoteInfo },
          };
          if (data.data.listAddBOM) {
            const _listAddBOM = data.data.listAddBOM.map((m) => ({
              ...m,
              isAdd: true,
            }));
            this.dataBOM.BOMInfo.push(..._listAddBOM);
          }
          this.isVisibleMoreInfo = false;
          this.isVisible = true;
        },
        (error) => {
          console.log("error :", error);
        }
      );
  }

  onCancel(): void {
    this.isVisible = false;
  }

  onExportExcelClick(): void {
    if (!(this.isAdmin || this.currentUser.user.isExportExcel)) {
      return;
    }
    const list = [
      {
        idx: 0,
        description: "Nett",
        price: this.dataBOM.quoteInfo.nett || 0,
      },
      {
        idx: 1,
        description: "Off Fascia Install",
        price: this.dataBOM.quoteInfo.fasciaInstall || 0,
      },
      {
        idx: 2,
        description: "Fly Over Install",
        price: this.dataBOM.quoteInfo.flyOverInstall || 0,
      },
      {
        idx: 3,
        description: "Install Mark up on material",
        price: this.dataBOM.quoteInfo.installMaterial || 0,
      },
      {
        idx: 4,
        description: "Per post install",
        price: this.dataBOM.quoteInfo.perPostInstall || 0,
      },
      {
        idx: 5,
        description: "Std Additional Costs",
        price: this.dataBOM.quoteInfo.addCost || 0,
      },
      {
        idx: 6,
        description: "Mark up on Supply Kits",
        price: this.dataBOM.quoteInfo.supplyOnlyKits || 0,
      },
      {
        idx: 7,
        description: "Additional Cost Install",
        price: this.dataBOM.quoteInfo.addCostInstall || 0,
      },
      {
        idx: 8,
        description: "GST (10.00%)",
        price: this.dataBOM.quoteInfo.gst || 0,
      },
      {
        idx: 9,
        description: "Total",
        price: this.dataBOM.quoteInfo.totalPrice || 0,
      },
    ];
    this.excelService.exportExcel(
      this.dataBOM.BOMInfo,
      list,
      this.jobNo,
      this.isAdmin
    );
  }
}
