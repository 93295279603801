<!-- BOM -->
<nz-modal
  [nzStyle]="{ top: '10px' }"
  [(nzVisible)]="isVisible"
  nzTitle="BOM - {{ jobNo }}"
  nzWidth="95%"
  (nzOnCancel)="onCancel()"
  [nzFooter]="null"
>
  <nz-table
    #tBOM
    [nzData]="dataBOM?.BOMInfo"
    [nzShowPagination]="false"
    [nzPageSize]="200"
    nzSize="small"
    [nzScroll]="{ y: '600px' }"
  >
    <!--
    [nzScroll]="{ x: '1150px', y: '240px' }"-->
    <thead>
      <tr>
        <th nzWidth="45px">Idx.</th>
        <th nzWidth="120px">Item No.</th>
        <th nzWidth="70px">Qty</th>
        <th nzWidth="70px">Length</th>
        <th nzWidth="90px">Cut BK</th>
        <th nzWidth="60px">Dir</th>
        <th>Description</th>
        <th nzWidth="70px">UoM</th>
        <th nzWidth="100px">Total UoM</th>
        <th nzWidth="110px">Price</th>
        <th nzWidth="110px">Unit Price</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let data of tBOM.data"
        [ngClass]="{ 'is-additional': data.isAdd }"
      >
        <td>{{ data.idx }}</td>
        <td>{{ data.itemNo }}</td>
        <td nzAlign="right">{{ data.qty }}</td>
        <td>{{ data.length }}</td>
        <td>{{ data.cutBk }}</td>
        <td>{{ data.dri }}</td>
        <td>{{ data.description }}</td>
        <td>{{ data.uom }}</td>
        <td nzAlign="right">{{ data.totalUom }}</td>
        <td nzAlign="right">{{ (isAdmin ? data.price : 0) | currency }}</td>
        <td nzAlign="right">{{ (isAdmin ? data.unitPrice : 0) | currency }}</td>
      </tr>
    </tbody>
  </nz-table>
  <nz-table
    [nzShowPagination]="false"
    [nzPageSize]="100"
    nzSize="small"
    [nzNoResult]="customTpl"
  >
    <thead>
      <th></th>
      <th nzWidth="220px"></th>
    </thead>
    <tbody>
      <tr [style.display]="isVisibleMoreInfo ? '' : 'none'">
        <td class="div-right bold">Nett</td>
        <td class="div-right bold">
          {{ dataBOM?.quoteInfo.nett || 0 | currency }}
        </td>
      </tr>
      <tr [style.display]="isVisibleMoreInfo ? '' : 'none'">
        <td class="div-right bold">Off fascia install</td>
        <td class="div-right bold">
          {{ dataBOM?.quoteInfo.fasciaInstall || 0 | currency }}
        </td>
      </tr>
      <tr [style.display]="isVisibleMoreInfo ? '' : 'none'">
        <td class="div-right bold">Fly Over install</td>
        <td class="div-right bold">
          {{ dataBOM?.quoteInfo.flyOverInstall || 0 | currency }}
        </td>
      </tr>
      <tr [style.display]="isVisibleMoreInfo ? '' : 'none'">
        <td class="div-right bold">Install markup of material</td>
        <td class="div-right bold">
          {{ dataBOM?.quoteInfo.installMaterial || 0 | currency }}
        </td>
      </tr>
      <tr [style.display]="isVisibleMoreInfo ? '' : 'none'">
        <td class="div-right bold">Per post install</td>
        <td class="div-right bold">
          {{ dataBOM?.quoteInfo.perPostInstall || 0 | currency }}
        </td>
      </tr>
      <tr [style.display]="isVisibleMoreInfo ? '' : 'none'">
        <td class="div-right bold">Std addtional costs</td>
        <td class="div-right bold">
          {{ dataBOM?.quoteInfo.addCost || 0 | currency }}
        </td>
      </tr>
      <tr [style.display]="isVisibleMoreInfo ? '' : 'none'">
        <td class="div-right bold">Mark up on supply kits</td>
        <td class="div-right bold">
          {{ dataBOM?.quoteInfo.supplyOnlyKits || 0 | currency }}
        </td>
      </tr>
      <tr [style.display]="isVisibleMoreInfo ? '' : 'none'">
        <td class="div-right bold">Addional cost install</td>
        <td class="div-right bold">
          {{ dataBOM?.quoteInfo.addCostInstall || 0 | currency }}
        </td>
      </tr>
      <tr [style.display]="isVisibleMoreInfo ? '' : 'none'">
        <td class="div-right bold">GST 10%</td>
        <td class="div-right bold">
          {{ dataBOM?.quoteInfo.gst || 0 | currency }}
        </td>
      </tr>
      <tr>
        <td class="div-right bold red">Total</td>
        <td class="div-right bold red">
          {{ dataBOM?.quoteInfo.totalPrice || 0 | currency }}
        </td>
      </tr>
    </tbody>
    <ng-template #customTpl>
      <div *ngIf="currentUser && currentUser.user.roles" nz-row nzType="flex">
        <div nz-col nzSpan="12" class="div-left">
          <span
            class="margin-right-5"
            [style.display]="
              currentUser?.user?.roles === objects.roles.user ? 'none' : ''
            "
            >More Information</span
          >
          <nz-switch
            [style.display]="
              currentUser?.user?.roles === objects.roles.user ? 'none' : ''
            "
            nzCheckedChildren="Show"
            nzUnCheckedChildren="Hide"
            [(ngModel)]="isVisibleMoreInfo"
            nzTooltipTitle="Show/Hide more information"
            nz-tooltip
          ></nz-switch>
        </div>
        <div nz-col nzSpan="12" class="text-align-right">
          <button
            [ngClass]="
              isAdmin || currentUser.user.isExportExcel
                ? 'small green ui button'
                : 'small green ui button disabled'
            "
            (click)="onExportExcelClick()"
          >
            <i class="file excel icon"></i>
            <span>Export Excel</span>
          </button>
          <button class="small red ui button" (click)="onCancel()">
            <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- <div>
        <nz-switch [style.display]="currentUser.user.roles ==='User' ? 'none':''" class="margin-right" [(ngModel)]="isVisibleMoreInfo" nzTooltipTitle="Show/Hide more information" nz-tooltip></nz-switch>
      </div>
      <div class="div-right">
        <button [ngClass]="isAdmin ? 'small green ui button' : 'small green ui button disabled'" (click)="onExportExcelClick()">
          <i class="file excel icon"></i>
          <span>Export Excel</span>
        </button>
        <button class="small red ui button" (click)="onCancel()">
          <span>Cancel</span>
        </button>
        <br>
      </div> -->
    </ng-template>
  </nz-table>
</nz-modal>
<!-- END BOM -->
